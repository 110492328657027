import React from 'react';
import { Link } from 'react-scroll';
import Pagetitle from '../elements/Pagetitle';
import Service from '../elements/Service';

const servicesData = [
    {
        id: 1,
        icon: 'images/service-1.svg',
        title: 'Frontend Development',
        content: 'JavaScript, React, Redux, SASS',
        color: '#FFBA08',
        contentColor: 'light',
    },
    {
        id: 2,
        icon: 'images/service-2.svg',
        title: 'Backend Development',
        content: 'Python 2/3, Django, PostgreSQL',
        color: '#A2AEBB',
        contentColor: 'light',
    },
    {
        id: 3,
        icon: 'images/service-3.svg',
        title: 'DevOps',
        content: 'Docker, Swarm, Makefile',
        color: '#3F88C5',
        contentColor: 'light',
    },
];

function Services() {
    return (
        <section id="services">
            <div className="container">
                <Pagetitle title="Services" />
                <div className="row fix-spacing">
                    {servicesData.map(service => (
                        <div className="col-md-4" key={service.id}>
                            <Service service={service} />
                        </div>
                    ))}
                </div>
                <div className="mt-5 text-center">
                    <p className="mb-0">
                        Looking for a custom job?{' '}
                        <Link className="colorpink pointer" to="section-contact" spy={true} smooth={true} duration={500}>
                            Click here
                        </Link>{' '}
                        to contact me! 👋
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Services;
