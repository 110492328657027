import React from 'react';
import TrackVisibility from 'react-on-screen';
import Counter from '../elements/Counter';
import Pagetitle from '../elements/Pagetitle';
import Skill from '../elements/Skill';

const aboutContent = {
    name: 'Paweł Stępak',
    avatarImage: '/images/avatar-1.png',
    content:
        'I am Paweł Stępak, web developer from Wrocław, Poland. ' +
        'I have rich experience in web site building and customization.',
};

const progressData = [
    {
        id: 1,
        title: 'Frontend',
        percantage: 85,
        progressColor: '#3F88C5',
    },
    {
        id: 2,
        title: 'Backend',
        percantage: 95,
        progressColor: '#D00000',
    },
    {
        id: 3,
        title: 'DevOps',
        percantage: 50,
        progressColor: '#1C3144',
    },
];

const counterData = [
    {
        id: 4,
        title: 'Projects completed',
        count: 10,
        icon: 'icon-badge',
    },
    {
        id: 2,
        title: 'Cup of coffee',
        count: 5670,
        icon: 'icon-cup',
    },
    {
        id: 3,
        title: 'Satisfied clients',
        count: 10,
        icon: 'icon-people',
    },
    {
        id: 4,
        title: 'Number of meetings',
        count: 3213,
        icon: 'icon-fire',
    },
];

function About() {
    return (
        <section id="about">
            <div className="container">
                <Pagetitle title="About Me" />
                <div className="row">
                    <div className="col-md-3">
                        <div className="text-center text-md-left">
                            <img src={aboutContent.avatarImage} alt={aboutContent.name} />
                        </div>
                        <div className="spacer d-md-none d-lg-none" data-height="30"></div>
                    </div>

                    <div className="col-md-9 triangle-left-md triangle-top-sm">
                        <div className="rounded bg-white shadow-dark padding-30">
                            <div className="row">
                                <div className="col-md-6">
                                    <p>{aboutContent.content}</p>
                                    <div className="mt-3">
                                        <a href="#" className="btn btn-default">
                                            Download CV
                                        </a>
                                    </div>
                                    <div className="spacer d-md-none d-lg-none" data-height="30"></div>
                                </div>
                                <div className="col-md-6">
                                    {progressData.map(progress => (
                                        <TrackVisibility once key={progress.id} className="progress-wrapper">
                                            <Skill progress={progress} />
                                        </TrackVisibility>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer" data-height="70"></div>
                <div className="row fix-spacing">
                    {counterData.map(counter => (
                        <div key={counter.id} className="col-md-3 col-sm-6">
                            <TrackVisibility once>
                                <Counter counterItem={counter} />
                            </TrackVisibility>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default About;
