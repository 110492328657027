import React from 'react';
import Pagetitle from '../elements/Pagetitle';
import Timeline from '../elements/Timeline';

const educationData = [
    {
        id: 1,
        title: 'Certificate',
        years: '2020 - Present',
        content: 'Niebezpiecznik.pl: Attacking and protecting web applications',
    },
    {
        id: 2,
        title: "Master's Degree",
        years: '2012 - Present',
        content: 'The topic of the master’s thesis: "Comparative analysis of queuing algorithms in virtual environment."',
    },
    {
        id: 3,
        title: 'Certificate',
        years: '2011 - 2014',
        content: 'Cisco: CCNA 640-802',
    },
];

const experienceData = [
    {
        id: 1,
        title: 'Senior Software Engineer - Shareablee',
        years: '2018 - Present',
        content: 'Python2/3, Django, JavaScript/React',
    },
    {
        id: 2,
        title: 'Full Stack Engineer - Mobile Vikings',
        years: '2016 - 2018',
        content: 'Python2, Django, JavaScript/Angular1.6',
    },
    {
        id: 3,
        title: 'Full Stack Engineer - Softserve',
        years: '2016 - 2016',
        content: 'Python2, Django, jQuery, bootstrap',
    },
    {
        id: 4,
        title: 'Python developer - Accenture',
        years: '2013 - 2015',
        content: 'Software development across Digital TV area',
    },
];

function Experiences() {
    return (
        <section id="experience">
            <div className="container">
                <Pagetitle title="Experience" />
                <div className="row">
                    <div className="col-md-6">
                        <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
                            {educationData.map(education => (
                                <Timeline key={education.id} education={education} />
                            ))}
                            <span className="line"></span>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="spacer d-md-none d-lg-none" data-height="30"></div>
                        <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
                            {experienceData.map(experience => (
                                <Timeline key={experience.id} education={experience} />
                            ))}
                            <span className="line"></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Experiences;
