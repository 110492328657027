import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { useLocation, Link as LinkDom } from 'react-router-dom';

function Header3({ light, logoSource, bootstrapNav }) {
    const [toggleNavbar, setToggleNavbar] = useState(false);
    const location = useLocation();
    const isBlogRoute = location.pathname.includes('blogs');
    const handleMobilenav = e => {
        e.preventDefault();
        setToggleNavbar(!toggleNavbar);
    };

    document.addEventListener('click', e => {
        if (e.target.closest('.content-3')) {
            setToggleNavbar(false);
        }
    });

    return (
        <header className={light ? 'desktop-header-3 fixed-top light' : 'desktop-header-3 fixed-top'}>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark">
                    <button
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        className="navbar-toggler"
                        data-target="#navbarNavDropdown"
                        data-toggle="collapse"
                        type="button"
                        onClick={handleMobilenav}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className={toggleNavbar ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'}
                        id="navbarNavDropdown"
                    >
                        <ul className="navbar-nav ml-auto scrollspy">
                            {isBlogRoute ? (
                                <>
                                    <li className="nav-item">
                                        <LinkDom activeClass="active" className="nav-link" to="/">
                                            Back to Homepage
                                        </LinkDom>
                                    </li>
                                    <li className="nav-item">
                                        <LinkDom activeClass="active" className="nav-link" to="/blogs">
                                            Blog posts
                                        </LinkDom>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className="nav-item">
                                        <Link
                                            activeClass="active"
                                            className="nav-link"
                                            to="section-home"
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                        >
                                            Home
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            activeClass="active"
                                            className="nav-link"
                                            to="section-about"
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                        >
                                            About
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            activeClass="active"
                                            className="nav-link"
                                            to="section-services"
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                        >
                                            Service
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            activeClass="active"
                                            className="nav-link"
                                            to="section-experiences"
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                        >
                                            Experience
                                        </Link>
                                    </li>
                                    {/*<li className="nav-item">*/}
                                    {/*  <Link*/}
                                    {/*    activeClass="active"*/}
                                    {/*    className="nav-link"*/}
                                    {/*    to="section-works"*/}
                                    {/*    spy={true}*/}
                                    {/*    smooth={true}*/}
                                    {/*    duration={500}*/}
                                    {/*  >*/}
                                    {/*    Works*/}
                                    {/*  </Link>*/}
                                    {/*</li>*/}
                                    <li className="nav-item">
                                        <Link
                                            activeClass="active"
                                            className="nav-link"
                                            to="section-blogs"
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                        >
                                            Blog
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            activeClass="active"
                                            className="nav-link"
                                            to="section-contact"
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                        >
                                            Contact
                                        </Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header3;
