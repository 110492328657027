import React from 'react';
import Typed from 'react-typed';
import { Link } from 'react-scroll';
import Particles from 'react-particles-js';

function Herosection(props) {
    const { x, y } = props.position;
    const { height, width } = props.elementDimensions;
    const activeParallax = (depth = 15) => {
        let posX = (width / 2 - x) / depth;
        let posY = (height / 2 - y) / depth;
        return {
            transform: `translate(${posX}px, ${posY}px)`,
            minHeight: '100%',
        };
    };

    return (
        <section id="home" className={props.light ? 'home d-flex align-items-center light' : 'home d-flex align-items-center'}>
            <div className="container">
                <div className="intro">
                    <h1 className="mb-2 mt-0">Paweł Stępak</h1>
                    <p>
                        I'm a{' '}
                        <Typed
                            strings={['Fullstack developer', 'Frontend developer', 'Backend developer', 'DevOps enthusiast']}
                            typeSpeed={70}
                            backSpeed={30}
                            attr="value"
                            loop
                        >
                            <label value></label>
                        </Typed>
                    </p>

                    <ul className="social-icons light list-inline mb-0 mt-4">
                        <li className="list-inline-item">
                            <a href="https://www.linkedin.com/in/pawe%C5%82-s-37808a56/">
                                <i className="fab fa-linkedin"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://github.com/paveu/">
                                <i className="fab fa-github"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://www.instagram.com/to.ja.pawel/">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </li>
                    </ul>

                    <div className="mt-4">
                        <Link className="btn btn-default" to="section-contact" spy={true} smooth={true} duration={500}>
                            Hire me
                        </Link>
                    </div>
                </div>

                <div className="scroll-down">
                    <Link to="section-about" spy={true} smooth={true} duration={500} className="mouse-wrapper">
                        <span>Scroll Down</span>
                        <span className="mouse">
                            <span className="wheel"></span>
                        </span>
                    </Link>
                </div>

                <div className="parallax" data-relative-input="true">
                    <div style={activeParallax(15)}>
                        <Particles
                            params={{
                                particles: {
                                    number: {
                                        value: 50,
                                    },
                                    size: {
                                        value: 3,
                                    },
                                },
                                interactivity: {
                                    events: {
                                        onhover: {
                                            enable: true,
                                            mode: 'repulse',
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Herosection;
